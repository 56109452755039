<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="ai_head">
        <van-notice-bar
          left-icon="volume-o"
          text="急重症患者不适合网上诊疗/咨询，请尽快前往医院就诊或拨打急救电话120/罗湖中心医院急救电话 1234567"
        />
      </div>
      <div class="ai_main"  ref="ai_main">
        <div class="ai_info">
          您已进入问诊流程 病人信息：{{ user.name }} {{ user.gender_label }}
          {{ format_birthDay(user.birthday) }}岁
        </div>
        <div class="ai_text">
          <div class="ai_box">
            <div class="ai_msg">
              您好, 我是互联网医院智能助理，请根据您的病情简单回答几个问题。
            </div>
          </div>

          <div class="before_commend">
            <div
              class="ai_history"
              v-if="specifice.history && specifice.history.length"
            >
              <div v-for="(item, idx) in specifice.history" :key="idx">
                <div class="ai_box">
                  <div class="ai_msg" v-html="item.text"></div>
                </div>
                <div class="ai_user_box">
                  <div class="ai_user_msg">{{ item.answer ? "是" : "否" }}</div>
                </div>
              </div>
            </div>

            <div class="ai_question" v-if="questions && questions.length">
              <div v-for="(item, index) in questions" :key="index">
                <div class="ai_box">
                  <div class="ai_msg" v-html="item.text"></div>
                </div>
                <div class="ai_user_box" v-if="item.answer !== undefined">
                  <div class="ai_user_msg">{{ item.answer ? "是" : "否" }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ai_box" v-if="show_conmmend">
            <div class="speed_box">
              <p class="speed_box-title">症状评估结果</p>
              <p class="speed_box-text">
                根据权威医学知识，结合疾病大数据分析，我们的分析结果：
              </p>
              <div class="speed_card">
                <div class="speed_card-head" v-if="data.recommends">
                  <div class="speed_card-title">
                    <div class="speed_card-headText">
                      <img
                        class="speed_card-icon"
                        width="16"
                        src="../../assets/img/ai-doc-icon.png"
                      />
                      {{ data.recommends[0].systems[0].name }}
                    </div>
                    <div class="speed_card-btn" @click="open_details">
                      推理依据 >
                    </div>
                  </div>
                  <div class="speed_card-content">
                    根据您有
                    <span class="ai_color">
                      {{data.recommends[0].symptom_text}}
                    </span>
                    的症状，可能是<span class="ai_color" v-if="data.recommends[0].classes"> {{
                      data.recommends[0].classes.join('、')
                    }} </span>问题，
                    属于
                    <span class="ai_color">{{
                      data.recommends[0].systems[0].name
                    }}</span>
                    建议及时就诊并完善检查检验。
                  </div>
                </div>

                <div class="speed_card-steps"  @click="choose_type(0,true)">
                  <div class="speed_card-steps-title">
                    <div class="speed_card-num">1</div>
                    <p class="speed_card-name">免排队快速诊断 <van-tag type="warning">推荐</van-tag></p>
                  </div>
                  <div class="speed_card-ad">
                    <div>
                      <div class="speed_card-ad-title">预计减少60%等待时间</div>
                      <div class="speed_card-ad-text">
                        免排队 先检查 在线问诊 送药上门
                      </div>
                    </div>
                    <div class="speed_card-ad-btn">了解更多</div>
                  </div>
                </div>

                <div class="speed_card-steps"  @click="choose_type(1)">
                  <div class="speed_card-steps-title">
                    <div class="speed_card-num">2</div>
                    <p class="speed_card-name">在线问诊就医</p>
                  </div>

                  <div class="speed_card-text">
                    建议您找
                    <span class="ai_color">{{
                      data.recommends[0].departments[0]
                    }}</span>
                    医生在线问诊
                  </div>
                </div>

                <div class="speed_card-steps"  @click="choose_type(2)">
                  <div class="speed_card-steps-title">
                    <div class="speed_card-num">3</div>
                    <p class="speed_card-name">预约挂号</p>
                  </div>

                  <div class="speed_card-text">
                    您也可以在线挂号：
                    <span class="ai_color">{{
                      data.recommends[0].departments[0]
                    }}</span>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="fast_box" v-if="fast_show">
            <p class="fast_box-tips">你已进入快速诊断流程</p>
            <div class="fast_card">
              <div class="fast_box-head">
                <div class="fast_card-title">推荐您尽快进行以下检验检查</div>
                <div class="fast_card-text">检查检验是疾病诊治的必要信息</div>
              </div>

              <div class="fast_card-center" v-if="data.recommends">
                <div
                  class="fast_card-items"
                  v-for="(item, idx) in lab_group"
                  :key="idx"
                  v-show="item.arr.length"
                >
                  <img
                    src="../../assets/img/ai_doc-ill.png"
                    alt=""
                    width="16"
                  />
                  <div class="fast_card_itemOut">
                    <div class="card_item-name">{{ item.name }}</div>
                    <div class="card_item-content">
                      <span v-for="(it, index) in item.arr" :key="index">
                        {{ it.standard_name }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="speed_card-ad blue">
                  <div>
                    <div class="speed_card-ad-title">
                      检查检验是疾病诊治的必要信息
                    </div>
                    <div class="speed_card-ad-text">
                      了解医生是如何确诊疾病的
                    </div>
                  </div>
                  <div class="speed_card-ad-btn">了解更多</div>
                </div>
              </div>

              <div class="fast_card-bottom">
                <div class="fast_card-next" @click="next()">预约检查</div>
                <div class="fast_card-save">
                  <img
                    src="../../assets/img/ai_doc-clock.png"
                    alt=""
                    width="12"
                  />
                  节省约60分钟就医时间
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-popup
        v-model="show_answer"
        round
        position="bottom"
        :style="{ height: '20%' }"
        :overlay="false"
        :close-on-click-overlay="false"
      >
        <div class="answer_pop">
          <div class="answer_title">
            <div class="answer_tips">请点击标签回答问题</div>
            <div class="answer_close" @click="confirm_answer">完成</div>
          </div>
          <div class="answer_box">
            <div
              class="answer_btn"
              :class="btn_active === idx ? 'active' : ''"
              v-for="(item, idx) in btn_msg"
              :key="item + idx"
              @click="choose_answer(idx)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="show_type"
        round
        position="bottom"
        :style="{ height: '20%' }"
        :overlay="false"
      >
        <div class="answer_pop">
          <div class="answer_title">
            <div class="answer_tips">请选择就诊方法</div>
          </div>
          <div class="answer_box">
            <div
              class="answer_btn"
              :class="type_active === idx ? 'active' : ''"
              v-for="(item, idx) in consultation_type"
              :key="item + idx"
              @click="choose_type(idx, true)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="details_pop"
        closeable
        position="bottom"
        :style="{ height: '80%' }"
      >
        <div class="details_pop">
          <div class="details_pop-head">推理依据</div>
          <div class="details_pop-inner">
            <div class="details_pop-box">
              <p>主诉症状</p>
              <p class="details_pop-text" v-if="data.recommends">
                <span class="ai_color">
                  {{ data.recommends[0].symptom_text || "症状" }}
                </span>
              </p>
            </div>

            <div class="details_pop-box" v-if="data.recommends">
              <p class="details_pop-title">
                <span>可能患病的身体系统</span>
                <span class="ai_color">{{
                  data.recommends[0].systems[0].name
                }}</span>
              </p>
              <div class="speed_card-content">
                根据您有
                <span class="ai_color">
                  {{data.recommends[0].symptom_text}}
                </span>
                的症状，可能是<span class="ai_color" v-if="data.recommends[0].classes"> {{
                  data.recommends[0].classes.join('、')
                }} </span>问题，
                属于
                <span class="ai_color">{{
                  data.recommends[0].systems[0].name
                }}</span>
                建议及时就诊并完善检查检验。
              </div>

              <div class="fast_box">
                <div class="fast_card">
                  <div class="fast_box-head">
                    <div class="fast_card-title">检查项目</div>
                    <div class="fast_card-text">检查检验是疾病诊治的必要信息</div>
                  </div>

                  <div class="fast_card-center" v-if="data.recommends">
                    <div
                      class="fast_card-items"
                      v-for="(item, idx) in lab_group"
                      :key="idx"
                      v-show="item.arr.length"
                    >
                      <img
                        src="../../assets/img/ai_doc-ill.png"
                        alt=""
                        width="16"
                      />
                      <div class="fast_card_itemOut">
                        <div class="card_item-name">{{ item.name }}</div>
                        <div class="card_item-content">
                          <span v-for="(it, index) in item.arr" :key="index">
                            {{ it.standard_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fast_card-bottom">
                    <div class="fast_card-next" @click="next()">预约检查</div>
                  </div>

                  <p class="details_pop-title">
                    <span>就诊科室</span>
                    <span class="ai_color">{{
                      data.recommends[0].departments[0]
                    }}</span>
                  </p>

                  <div class="fast_card-bottom">
                    <div class="fast_card-next" @click="choose_type(1)">在线问诊</div>
                    <div class="fast_card-next" @click="future_fn">预约挂号</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="details_pop-box" v-if="data.recommends && optional_all.length">
              <div class="fast_card-title">推荐补充检查检验项目</div>
              <div class="fast_card-text">选择以下项目可以更快诊断疾病排筛其他疾病风险</div>
              <div class="recommend_blue_box">
                <span v-for="item in optional_all" :key="item.standard_id">
                  {{item.standard_name}}
                </span>
              </div>
            </div>

            <div class="details_pop-box" v-if="data.recommends">
              <p>存在{{ data.recommends[0].systems[0].name }}</p>
              <p class="details_pop-text">
                {{ data.recommends[0].systems[0].introduction }}
              </p>
              <div class="myCharts">
                <MyChart :data="options" :show="details_pop" />
              </div>
              <div class="chart_text">
                * 线条越粗关联性越强
              </div>
            </div>

            <div class="details_pop-box mt20">
              <p>推理依据</p>
              <p class="details_pop-text">
                本推理依据参考 3本权威医学书籍，结出该评估报告
              </p>
            </div>

            <div class="details_pop-box flex_box">
              <p>这次评估是否准确？</p>
              <div class="details_like-box">
                <div
                  class="details_useful"
                  :class="this.like ? 'active' : ''"
                  @click="send_like(true)"
                >
                  <p>有用</p>
                  <div class="like_icon"></div>
                </div>
                <div
                  class="details_useful s2"
                  :class="this.like === false ? 'active' : ''"
                  @click="send_like(false)"
                >
                  <p>没用</p>
                  <div class="like_icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="show_commit"
        position="bottom"
        round
        :style="{ height: '30%' }"
      >
        <div class="commit_box">
          <div class="commit_title">这次评估是否准确？</div>
          <textarea
            class="ai_textarea"
            v-model="commit"
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder="谈谈您的看法"
          ></textarea>
          <div class="commit_bottom">
            <div class="commit_btn" @click="submit_like">提交</div>
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="show_situation"
        position="bottom"
        :close-on-click-overlay="false"
        round
        :style="{ minHeight: '30%' }"
      >
        <div class="situation_box">
          <div class="situation_title">
            {{ un_suitabel.name }}检查不适宜人群告知
          </div>
          <div class="situation_sub">请明确您不存在如下情况</div>
          <div class="situation_list" v-html="un_suitabel.des"></div>
          <div class="situation_bottom">
            <div class="situation_btn situation_btn_active" @click="send_situation(true)">
              我不存在以上情况
            </div>
            <div class="situation_btn" @click="send_situation(false)">
              我有以上某项情况
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="hurry"
        overlay-class="hurry_pop"
        :close-on-click-overlay="false"
      >
        <div class="hurry_box">
          <img
            class="hurry_bg"
            src="../../assets/img/hurry_pop_bg.png"
            width="80"
            alt=""
          />
          <p class="hurry_text">
            {{ hurry_text }}
          </p>
          <router-link :to="{ path: 'consultation' }">
            <p class="hurry_btn">知道了</p>
          </router-link>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { comments, get_next, get_specificity, get_comments, get_order_id } from "./service";
import { get_url_params, format_birthDay } from "../../utils/format";
import MyChart from "./MyCharts";
import { isEmptyObj } from '../../utils/validate';
export default {
  data() {
    return {
      id: 0,
      btn_active: -1,
      type_active: -1,
      btn_msg: ["是", "否"],
      consultation_type: ["免排队快速就诊", "在线问诊", "预约挂号"],
      show_answer: false,
      hurry: false,
      age: -1,
      details_pop: false,
      fast_show: false,
      show_type: false,
      options: null,
      like: null,
      commit: "",
      show_commit: false,
      show_situation: false,
      lab_group: [
        {
          name: "血",
          arr: [],
        },
        {
          name: "尿",
          arr: [],
        },
        {
          name: "粪便",
          arr: [],
        },
        {
          name: "其他",
          arr: [],
        },
        {
          name: "检查",
          arr: [],
        },
      ],
      un_suitabel: {
        name: "",
        des: "",
      },
      un_suitabel_idx: -1,
      specifice: {},
      questions: [],
      last_question: {},
      hurry_text: "",
      optional_all: [],
      recommend_data: null,
      timer: null,
      scroll_distance: 10,
    };
  },
  computed: {
    ...mapState("illList", {
      user: (state) => state.user,
      user_ills: (state) => state.illList,
    }),
    ...mapState("exam", {
      data: (state) => state.data,
      loading: (state) => state.loading,
      show_conmmend: (state) => state.show_conmmend,
    }),
  },
  components: {
    MyChart,
  },
  watch: {
    age(value) {
      this.hurry = value < 14;
      this.hurry_text = '暂不支持14岁以下患者就诊'
    },
    show_conmmend(value) {
      this.show_type = value;
      this.$nextTick(this.to_bottom);
    },
    details_pop() {
      this.options = {
        name: this.data.recommends[0].systems[0].name,
        children: [...this.data.recommends[0].symptoms],
      };
    },
  },
  created() {
    this.clearState()
    this.age = format_birthDay(this.user.birthday)
    if(this.age > 13) {
      this.init();
    }
  },
  methods: {
    update_questions(data){
      if(data.highlight) {
        data.text = data.text.replace(data.highlight, `<span class="ai_color">${data.highlight}</span>`)
      }
      this.questions.push(data)
    },
    async specificity_info(id) {
      this.show_loading();
      try {
        const { data } = await get_specificity(id);
        this.specifice = data;
        this.last_question = data.question
        this.update_questions(this.last_question)
        this.show_answer = true;
        this.$nextTick(this.to_bottom);
      } finally {
        this.hide_loading();
      }
    },

    async next_info(payload) {
      this.show_loading();
      try {
        const { data } = await get_next(payload);
        this.last_question = data
        this.update_questions(data)
      } finally {
        this.hide_loading();
        this.show_answer = true;
        this.$nextTick(this.to_bottom);
      }
    },

    get_url_params,
    format_birthDay,
    ...mapMutations("exam", ["clearState"]),
    ...mapMutations("exam", ["updateGroup"]),
    ...mapMutations("exam", ["updateOptional"]),
    ...mapMutations("exam", ["show_loading"]),
    ...mapMutations("exam", ["hide_loading"]),
    ...mapActions("exam", ["get_recommend"]),
    init() {
      this.id = this.get_url_params().id;
      this.specificity_info(this.id);
    },

    next() {
      this.situation_pop();
    },
    async update_to_page() {
      let payload = {
        examine_ids: [],
        laboratory_ids: [],
      }
      let exam = this.data.recommends[0].examinations
      let lab = this.data.recommends[0].laboratories
      if(exam.length) {
        exam.forEach(item=>{
          if(payload.examine_ids.indexOf(item.standard_id) < 0) {
            payload.examine_ids.push(item.standard_id)
          }
        })
      }
      if(lab.length) {
        lab.forEach(item=>{
          if(payload.laboratory_ids.indexOf(item.standard_id) < 0) {
            payload.laboratory_ids.push(item.standard_id)
          }
        })
      }
      this.show_loading()
      try {
        const {data} = await get_order_id(payload)
        this.$router.push({ path: "pre-order", query: { order_id: data.order_id }});
      } finally {
        this.hide_loading()
      }
    },
    async submit_like() {
      if (this.like === null) return;
      this.show_loading();
      try {
        await comments(this.id, {
          like: this.like,
          comment: this.commit,
        });
      } finally {
        this.hide_loading();
        this.$notify({ type: "success", message: "提交成功" });
        this.show_commit = false;
      }
    },
    async get_like(){
      this.show_loading();
      try {
        const {data} = await get_comments(this.id);
        if(!isEmptyObj(data)) {
          this.commit = data.comment
          this.like = data.like
        } else {
          this.commit = ''
          this.like = null
        }
      } finally {
        this.hide_loading();

      }
    },
    choose_type(idx, show_card) {
      //(idx)
      this.optional_all = []
      if(show_card) this.type_active = idx;
      switch(idx) {
      case 0: {
        this.lab_group = [
          {
            name: "血",
            arr: [],
          },
          {
            name: "尿",
            arr: [],
          },
          {
            name: "粪便",
            arr: [],
          },
          {
            name: "其他",
            arr: [],
          },
          {
            name: "检查",
            arr: [],
          },
        ]

        let pause_arr_lab = this.data.recommends[0].laboratories.filter((item)=>{
          return !item.optional
        })

        let optional_lab = this.data.recommends[0].laboratories.filter((item)=>{
          return item.optional
        })

        let optional_exam = this.data.recommends[0].examinations.filter((item)=>{
          return item.optional
        })

        pause_arr_lab.forEach((item) => {
          switch (item.specimen) {
          case "血":
            this.lab_group[0].arr.push(item);
            break;
          case "尿":
            this.lab_group[1].arr.push(item);
            break;
          case "粪便":
            this.lab_group[2].arr.push(item);
            break;
          default:
            this.lab_group[3].arr.push(item);
          }
        });

        let pause_arr_exam = this.data.recommends[0].examinations.filter((item)=>{
          return !item.optional
        })

        this.optional_all = [...optional_lab, ...optional_exam]
        this.lab_group[4].arr = [...pause_arr_exam];
        this.recommend_data = {id: this.id}
        if(show_card) {
          this.$nextTick(this.to_bottom);
          this.fast_show = true;
          this.show_type = false;
        }
      }
        break;
      case 1:
        this.$router.push({path:'online-inquiry'})
      //   break;
      // case 2:
      //   this.future_fn()
      //   break
      }
    },
    future_fn(){
      this.$notify({ type: "success", message: "暂未开放，敬请期待" });
    },
    situation_pop() {
      this.un_suitabel_idx++;
      this.un_suitabel = {
        name: "",
        des: "",
      };

      let arr = [
        ...this.data.recommends[0].laboratories,
        ...this.data.recommends[0].examinations,
      ].filter((item)=>{
        return !item.optional
      });
      for (let i = this.un_suitabel_idx; i < arr.length; i++) {
        let v = arr[i];
        if (v.un_suitable) {
          this.un_suitabel.name = v.standard_name;
          this.un_suitabel_idx = i;
          this.un_suitabel.des = v.un_suitable.split("；").join("<br>");
          break;
        }
      }
      this.$nextTick(() => {
        this.show_situation = !!this.un_suitabel.name;
        if (!this.show_situation) {
          this.update_to_page();
        }
      });
    },
    choose_answer(idx) {
      this.btn_active = idx;
      this.confirm_answer();
    },
    confirm_answer() {
      if (this.btn_active < 0) {
        return;
      }
      this.show_answer = false;
      this.last_question.answer = this.btn_active < 1;
      let my_answer = this.last_question.answer
        ? this.last_question.y
        : this.last_question.n
      switch (my_answer.type) {
      case 1:
        this.next_info({
          question_id: this.id,
          id: this.last_question.id,
          answer: {
            answer: this.last_question.answer,
          },
        });
        break;
      case 2:
        if (my_answer.message) {
          this.hurry_text = my_answer.message;
          this.hurry = true;
        } else {
          this.$router.push({ path: "/consultation" });
        }
        break;
      default:
        this.get_recommend({
          id: this.id,
        });
      }
      this.btn_active = -1;
    },
    open_details() {
      this.details_pop = true;
      this.choose_type(0)
      this.get_like()
    },
    send_like(v) {
      this.like = v;
      this.details_pop = false;
      this.show_commit = true;
    },

    send_situation(v) {
      let lab = this.data.recommends[0].laboratories.filter((item)=>{return !item.optional});
      let exam = this.data.recommends[0].examinations.filter((item)=>{return !item.optional});
      if (this.un_suitabel_idx < lab.length) {
        lab[this.un_suitabel_idx].selected = v;
      } else {
        exam[this.un_suitabel_idx - lab.length].selected = v;
      }
      this.situation_pop();
    },

    loading_finished() {
      this.scroll_distance = 10
      this.$nextTick(this.to_bottom);
    },

    to_bottom() {
      let dom = this.$refs.ai_main;
      dom.scrollTop += this.scroll_distance
      this.scroll_distance += 1.5
      if(dom.scrollTop < (dom.scrollHeight - dom.clientHeight)){
        this.timer = setTimeout(this.to_bottom, 17)
      } else {
        this.clear_timer()
      }
    },
    to_top() {
      const dom = this.$refs.out;
      dom.scrollTop -= this.scroll_distance
      this.scroll_distance += 1.5
      if(dom.scrollTop > 0){
        this.timer = setTimeout(this.to_top, 17)
      } else {
        this.clear_timer()
      }
    },

    clear_timer(){
      clearTimeout(this.timer)
    }
  },
};
</script>

<style scoped>
.g_main_content {
  padding-bottom: 0;
}

.ai_main {
  background: #f5f5f5;
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 18px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #565656;

  &.show_conmmend {
    padding-bottom: 1vh;
  }
}

.ai_info {
  font-size: 12px;
  line-height: 19px;
  color: #92abc1;
  text-align: center;
}

.ai_box {
  margin-top: 18px;
}

.ai_msg {
  max-width: 234px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0 9px 9px 9px;
  line-height: 18px;
  padding: 8.5px 10px;
  position: relative;
  display: inline-block;
  font-size: 14px;
  & >>> .ai_color {
    color: #1890ff;
  }
}

.ai_user_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;
}

.ai_user_msg {
  max-width: 234px;
  background: #0088ff;
  color: #ffffff;
  opacity: 1;
  border-radius: 9px 0 9px 9px;
  line-height: 18px;
  padding: 8.5px 10px;
  position: relative;
  box-sizing: border-box;
}

.ai_color {
  color: #1890ff;
}

.ai_large {
  font-size: 16px;
  padding: 4px 0 6px;
}

.ai_small {
  font-size: 13px;
}

.ai_rs_box {
  padding: 12px 16px;
  background: #f4f9fe;
  opacity: 1;
  border-radius: 9px;
  color: #242938;
  margin-top: 8px;
}

.rs_list_out {
  display: flex;
  align-items: center;
}

.rs_point {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  text-align: center;
  height: 13px;
  line-height: 13px;
  background: #ccc;
  border-radius: 9px 9px 0 9px;
  font-size: 10px;
  color: #ffffff;
  margin-right: 8px;

  &.active {
    background: #0088ff;
  }
}

.rs_list_box {
  line-height: 20px;
  flex: 1;
}

.ai_rs_btn {
  width: 113px;
  height: 30px;
  background: #1890ff;
  opacity: 1;
  border-radius: 1px;
  margin: 12px 0;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}
.ai_rs_btn {
  width: 113px;
  height: 30px;
  background: #1890ff;
  opacity: 1;
  border-radius: 1px;
  margin: 12px 0;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    background: #ccc;
  }
}

.answer_pop {
  padding: 16px;
   -webkit-box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
  box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
}

.answer_title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.answer_tips {
  font-size: 16px;
  line-height: 16px;
  color: #333;
  font-weight: 600;
}

.answer_close {
  width: 49px;
  height: 24px;
  border: 1px solid #0088ff;
  opacity: 1;
  border-radius: 15px;
  font-size: 12px;
  line-height: 24px;
  color: #0088ff;
  text-align: center;
}

.answer_box {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}

.answer_btn {
  min-width: 70px;
  height: 32px;
  opacity: 1;
  border-radius: 19px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  margin-right: 15px;
  padding: 0 10px;
  border: 1px solid #d9d9d9;
  color: #565656;
  margin-bottom: 5px;

  &.active {
    background: #0088ff;
    color: #ffffff;
    border-color: #0088ff;
  }
}

.hurry_box {
  width: 314px;
  height: 130px;
  opacity: 1;
  border-radius: 12px;
  font-size: 14px;
  color: #4f2623;
  padding: 28px 16px;
  line-height: 16px;
  background: #ffeaea;
  background-size: cover;
}

.hurry_bg {
  position: absolute;
  top: 0;
  right: 0;
}

.hurry_text {
  max-width: 205px;
}

.hurry_btn {
  position: relative;
  width: 99px;
  height: 32px;
  background: #d16262;
  border-radius: 12px;
  margin-top: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
}

.hurry_pop + .van-popup {
  background-color: transparent;
}

.before_commend {
  padding-bottom: 20vh;
}

.speed_box {
  margin-top: -20vh;
  padding-top: 5px;
  padding-bottom: 20vh;
}

.speed_box-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
}

.speed_box-text {
  font-size: 12px;
  margin-top: 10px;
  color: #909090;
}

.speed_card {
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  padding: 20px 15px;
  margin-top: 14px;
}

.speed_card-head {
  padding-bottom: 11px;
  border-bottom: solid 1px rgba(112, 112, 112, 0.17);
}

.speed_card-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.speed_card-headText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #0088ff;
  display: flex;
  align-items: center;
}

.speed_card-icon {
  margin-right: 5px;
}

.speed_card-btn {
  font-size: 12px;
  line-height: 19px;
  color: #565656;
}

.speed_card-content {
  padding-top: 12px;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.speed_card-steps {
  padding-top: 20px;
}

.speed_card-steps-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
}

.speed_card-num {
  width: 16px;
  height: 16px;
  color: #ffffff;
  background-color: #0088ff;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  margin-right: 5px;
  border-radius: 50%;
}

.speed_card-ad {
  background: #edfef6;
  padding: 14px 16px;
  margin-top: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #234f3f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.speed_card-ad.blue {
  background: #eaf7ff;

  & .speed_card-ad-btn {
    background: #0088ff;
  }
}

.speed_card-ad-text {
  font-size: 12px;
  line-height: 16px;
  color: #70988a;
}

.speed_card-ad-btn {
  width: 62px;
  height: 21px;
  background: #62d1a6;
  border-radius: 19px;
  font-size: 12px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.speed_card-text {
  margin-top: 9px;
  font-size: 14px;
  line-height: 19px;
  color: #565656;
  padding-left: 20px
}

.details_pop {
  background: #f2f7f8;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
}

.details_pop-head {
  height: 55px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #3d3d3d;
  line-height: 55px;
  padding: 0 16px;
}

.details_pop-inner {
  padding: 20px 16px;
  height: calc(80vh - 55px);
  overflow-y: auto;
}

.details_pop-box {
  padding: 14px 12px;
  border-radius: 15px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
  margin-bottom: 20px;

  &.mt20 {
    margin-top: 20px;
  }
}

.details_pop-text {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.details_pop-title {
  font-size: 16px;
  line-height: 19px;
  color: #565656;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.flex_box {
  display: flex;
}

.details_like-box {
  margin-left: 15px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;

  & div {
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;

    & img {
      margin-left: 5px;
    }
  }
}

.details_useful {
  display: flex;
  align-items: center;
  color: #565656;

  & .like_icon {
    width: 16px;
    height: 16px;
    background: url("../../assets/img/ai_like_normal.png") center no-repeat;
    background-size: cover;
    margin-left: 5px;
  }

  &.active {
    color: #62d1a6;

    & .like_icon {
      background: url("../../assets/img/ai_doc-like.png") center no-repeat;
      background-size: cover;
    }
  }

  &.s2 {
    & .like_icon {
      width: 16px;
      height: 16px;
      background: url("../../assets/img/ai_unlike_normal.png") center no-repeat;
      background-size: cover;
    }

    &.active {
      color: #dfae63;

      & .like_icon {
        background: url("../../assets/img/ai_doc-unlike.png") center no-repeat;
        background-size: cover;
      }
    }
  }
}


.details_pop-box {
  & .fast_box {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.fast_box {
  margin-top: -20vh;
  padding-bottom: 10px;
}


.fast_box-tips {
  font-size: 14px;
  line-height: 19px;
  color: #92abc1;
  text-align: center;
  margin: 16px 0;
}

.fast_card {
  padding: 20px 15px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  color: #565656;
}

.details_pop-box {
  & .fast_card {
    padding: 20px 0 0 0;
    box-shadow: none;
  }

  & .fast_box-head,
  & .fast_card-center {
    border-bottom: none;
  }
}

.fast_box-head {
  padding-bottom: 14px;
  border-bottom: solid 1px rgba(112, 112, 112, 0.17);
}

.fast_card-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.fast_card-text {
  margin-top: 3px;
  font-size: 12px;
  line-height: 19px;
}

.fast_card-center {
  padding: 18px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.17);
}

.fast_card-items {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: flex-start;
}

.fast_card_itemOut {
  flex: 1;
  margin-left: 5px;
}

.card_item-content {
  font-size: 12px;
  line-height: 19px;
  color: #0088ff;
  margin-top: 2px;
   font-weight: 400;
}

.fast_card-bottom {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.fast_card-next {
  min-width: 74px;
  height: 32px;
  background: #0088ff;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-right: 23px;
  border-radius: 16px;
}

.fast_card-save {
  height: 21px;
  background: #fefced;
  padding: 0 9px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 21px;
  color: #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;

  & img {
    margin-right: 5px;
  }
}

.myCharts {
  margin: 10px 0;
}

.ai_textarea {
  width: 100%;
  height: 73px;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  display: block;
  padding: 10px;
  font-size: 12px;
  margin-top: 15px;
}

.commit_box {
  padding: 22px 16px;
}

.commit_title {
  font-size: 14px;
  line-height: 16px;
  color: #b9b9b9;
}

.commit_bottom {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}

.commit_btn {
  width: 102px;
  height: 34px;
  background: #0088ff;
  opacity: 1;
  border-radius: 6px;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff;
  text-align: center;
}

.situation_box {
  padding: 24px;
}

.situation_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
}

.situation_sub {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #565656;
  margin-bottom: 14px;
margin-top: 4px;
}

.situation_list {
  font-size: 14px;
  line-height: 17px;
  color: #707070;
  margin-bottom: 23px;
}

.situation_bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.situation_btn {
  width: calc((100% - 12px) / 2);
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #0088ff;
  text-align: center;
  border: 1px solid #eaeaea
}

.situation_btn_active {
  width: calc((100% - 12px) / 2);
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  background: #0088ff;
  text-align: center;
  border: 1px solid #0088ff
}



.chart_text {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: orange;
}

.recommend_blue_box {
  font-size: 12px;
  line-height: 19px;
  padding-top: 10px;
  color: #0088ff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;

  & span {
    margin-right: 5px;
  }
}
</style>
