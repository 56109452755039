<template>
  <div class="chart" ref="myChart" :style="{height: auto_height}"></div>
</template>
<script>
import { format_decimal } from "../../utils/format";

export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
    show: {
      default: () => {},
      type: Boolean,
    },
  },
  data() {
    return {
      timer: null,
      source: [],
      links: [],
      auto_height: '200px'
    };
  },
  computed: {},
  watch: {
    show(v) {
      if (v) this.init_echart();
    },
  },
  components: {},
  created() {},
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.myChart);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.resize, false);
      this.myChart.clear();
      echarts.dispose(this.myChart);
      this.myChart = null;
    });

    this.init_echart();
    window.addEventListener("resize", this.resize, false);
  },
  methods: {
    format_decimal,
    resize() {
      // 防抖，有自定义图形组件时使用
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.myChart.resize();
        this.echarts();
      }, 50);
    },
    // 初始数据
    init_data() {
      if (this.data.children && this.data.children.length) {
        this.source = []
        this.links = []
        this.data.children.forEach((el) => {
          if(el.layer === 1) {
            this.source.push(el)
          }
        });
        this.source.push({
          name: this.data.name,
        });

        this.source.forEach((el, idx) => {
          el.x = 0;
          el.y = 30 * idx;
          el.label = {
            show: true,
            position: 'left',
          }
          if (idx === this.source.length - 1) {
            el.x = 250;
            el.y = 0;
            el.label = {
              show: true,
              position: 'right',
            }
          }
          this.links.push({
            source: idx,
            target: this.source.length - 1,
            label: {
              show: false,
            },
            lineStyle: {
              width: el.weight * 10,
              curveness: -0.016 * idx,
            },
          })
        })
      }
      if(this.links.length) {
        this.auto_height = 25*(this.links.length - 1) + 'px'
      }
    },
    init_echart() {
      this.init_data();
      this.$nextTick(() => {
        this.myChart.resize();
        this.echarts();
      });
    },
    echarts() {
      let option = {
        grid: {
          top: '5%',
          left: '3%',
          right: '3%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            type: "graph",
            left: 70,
            right: 80,
            symbolSize:1,
            edgeSymbol : [ 'none', 'none' ],
            edgeSymbolSize : 10,
            itemStyle:{
              normal: {
                show: false
              }
            },
            label: {
              normal: {
                show: true,
                textStyle : { 
                  color : '#565656', 
                  fontStyle : 'normal',
                  fontSize : 12, 
                }
              }
            },
            data: [
              ...this.source
            ],
            links: [
              ...this.links
            ],
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
